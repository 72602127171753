import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { MailBaseType, MailParamType } from '../../propTypes';
import { upperCaseToCamelWithSpaces } from '../../helpers/upper-case-to-camel-case-with-spaces';

const MailParametersFilter = (
  { selectedMailParam, setSelectedMailParam, mailParams, specificMode, ...props },
) => {
  const options = mailParams;

  if (!mailParams || mailParams.length === 0) {
    return <Typography>Aún no existen parámetros asociados</Typography>;
  }

  const uniqueKeyString = (option) => {
    if (!option.requestingPlatform) return 'Seleccione un parámetro del mail';
    const template = option.template.version || 'S/V';
    return `${option.country.value}-${upperCaseToCamelWithSpaces(option.requestingPlatform.value)}-${template}`;
  };

  return (
    <Autocomplete
      id="select-mail-parameters"
      options={options}
      disabled={specificMode}
      getOptionLabel={(option) => uniqueKeyString(option)}
      onChange={(_, value) => { setSelectedMailParam(value); }}
      value={selectedMailParam}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label="Seleccionar combinación" />
      )}
      {...props}
    />
  );
};

MailParametersFilter.propTypes = {
  selectedMailParam: MailBaseType,
  setSelectedMailParam: PropTypes.func.isRequired,
  mailParams: PropTypes.arrayOf(MailParamType),
  specificMode: PropTypes.bool.isRequired,
};

MailParametersFilter.defaultProps = {
  selectedMailParam: null,
  mailParams: [],
};
export default MailParametersFilter;
