import {
  Autocomplete,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAsyncHookSetter, useGetBackOfficeUser } from '../../hooks';
import useGenericGetExecutives from '../../hooks/useGenericGetExecutive';

const ExecutiveFilter = ({ setExecutiveCallback }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const queryFilter = { executive_Active: true, globalFilter };
  const { executives: commercialExecutives } = useGenericGetExecutives(queryFilter);
  const [selectedExecutive, setSelectedExecutive] = useState('');
  const options = useMemo(() => commercialExecutives
    .map((i) => i.node) || [], [commercialExecutives]);
  const currentUser = useGetBackOfficeUser();
  const [setAsyncHook] = useAsyncHookSetter(setGlobalFilter);
  if (currentUser?.isStaff && currentUser?.executive?.isCommercialExecutive) {
    return null;
  }

  return (
    <Autocomplete
      disablePortal
      id="executive-filter"
      options={options}
      getOptionLabel={(option) => option?.completeName || ''}
      onChange={(_, value) => {
        setSelectedExecutive(value);
        setExecutiveCallback(value);
      }}
      sx={{ minWidth: 90 }}
      value={selectedExecutive}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(_options, params) => {
        setAsyncHook(params.inputValue);
        return _options;
      }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ejecutivo"
          InputProps={{ ...params.InputProps }}
        />

      )}
    />
  );
};

ExecutiveFilter.propTypes = {
  setExecutiveCallback: PropTypes.func,
};

ExecutiveFilter.defaultProps = {
  setExecutiveCallback: () => {},
};

export default ExecutiveFilter;
