import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import { useQuickFilterButtons } from '../../hooks';

const FingoQuickFilter = ({ states, setStates, buttonsToShow }) => {
  const selectableButtons = useQuickFilterButtons();
  const buttons = useMemo(() => selectableButtons, [selectableButtons]);
  const [isActive, setIsActive] = useState({});
  const eraser = useMemo(() => ({
    ...states,
    ...buttons.map(({ key, defaultValue }) => ({ [key]: defaultValue })),
  }), [states]);
  const updateIsActive = (actualStates) => {
    setIsActive(
      Object.assign(...buttons.map(({ id, getIsActive }) => ({ [id]: getIsActive(actualStates) }))),
    );
  };
  const handleOnClick = useCallback((id, key, value) => () => {
    /* Change the external-hook filter */
    if (isActive[id]) {
      setStates({ ...eraser, [key]: null });
    } else {
      setStates({ ...eraser, [key]: value });
    }
    /* Change the colors of buttons */
  }, [isActive, eraser, setStates]);
  useEffect(() => {
    updateIsActive(states);
  }, [states]);
  return (
    <Hidden mdDown>
      { buttons.filter(({ id }) => buttonsToShow.some(
        (buttonToShow) => id.includes(buttonToShow),
      )).map((button) => {
        const { id, key, value, title, color, EndIcon } = button;
        const extraProps = isActive[id] ? { variant: 'contained', color: 'primary' } : {};
        return (
          <Button
            key={`quick-filter-${id}`}
            id={`quick-filter-${id}`}
            startIcon={<EndIcon sx={{ color: isActive[id] ? 'white' : color }} fontSize="small" />}
            color={isActive[id] ? 'primary' : 'text'}
            size="small"
            sx={{
              bgcolor: isActive[id] ? 'primary.main' : 'white',
              borderColor: { md: 'primary.main', xs: 'text.primary' },
              borderStyle: 'solid',
              minWidth: 124,
            }}
            onClick={handleOnClick(id, key, value)}
            {...extraProps}
          >{title}
          </Button>
        );
      })}
      <Divider orientation="vertical" variant="middle" sx={{ height: '32px', alignSelf: 'center' }} flexItem />
    </Hidden>
  );
};

FingoQuickFilter.propTypes = {
  setStates: PropTypes.func.isRequired,
  states: PropTypes.shape().isRequired,
  buttonsToShow: PropTypes.arrayOf(PropTypes.string),
};

FingoQuickFilter.defaultProps = {
  buttonsToShow: [],
};

export default FingoQuickFilter;
