import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const FILTER_OPTIONS = [
  {
    label: 'Todos los canales',
    value: 'All',
    key: 'everyChannel',
  },
  {
    label: 'Fingo',
    value: 'Fingo',
    key: 'fingoChannel',
  },
  {
    label: 'Fingo Manual',
    value: 'Fingo Manual',
    key: 'fingoManualChannel',
  },
  {
    label: 'Convenio',
    value: 'Convenio',
    key: 'convenioChannel',
  },
  {
    label: 'Referido',
    value: 'Referido',
    key: 'referidoChannel',
  },
  {
    label: 'Corporativo',
    value: 'Corporativo',
    key: 'corporativoChannel',
  },
  {
    label: 'Senegocia',
    value: 'Integraciones - senegocia',
    key: 'senegociaChannel',
  },
  {
    label: 'Manager',
    value: 'Integraciones - managermas',
    key: 'managerChannel',
  },
  {
    label: 'Acepta',
    value: 'Integraciones - acepta',
    key: 'aceptaChannel',
  },
  {
    label: 'Skualo',
    value: 'Integraciones Skualo',
    key: 'skualoChannel',
  },
  {
    label: 'Santander',
    value: 'Integraciones - Santander',
    key: 'santanderChannel',
  },
  {
    label: 'Santander Manual',
    value: 'Integraciones - Santander Manual',
    key: 'santanderManualChannel',
  },
];

const MasterEntitySourceFilter = ({ setMasterEntitySourceCallback }) => {
  const [selectedFilter, setSelectedFilter] = useState(FILTER_OPTIONS[0].value);
  const setMasterEntitySource = (e) => {
    const newFilter = e.target.value;
    const selectedOption = FILTER_OPTIONS.find((option) => option.value === newFilter);

    setSelectedFilter(newFilter);
    setMasterEntitySourceCallback(selectedOption.value === 'All' ? '' : selectedOption.value);
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 115 }}>
      <Select
        name="filterType"
        value={selectedFilter}
        onChange={setMasterEntitySource}
      >
        {FILTER_OPTIONS.map((filter) => (
          <MenuItem
            value={filter.value}
            key={filter.key}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MasterEntitySourceFilter.propTypes = {
  setMasterEntitySourceCallback: PropTypes.func,
};

MasterEntitySourceFilter.defaultProps = {
  setMasterEntitySourceCallback: () => {},
};

export default MasterEntitySourceFilter;
