import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import INVOICE_OPERATION_STATES_OPTIONS from '../../constants/invoice-operation-states-options';

const InvoiceStatusFilter = ({ states, setStates }) => {
  const [selectedStatus, setSelectedStatus] = useState('All');
  const { status, ...rest } = states;
  const handleChangeInvoiceStatus = (e) => {
    setSelectedStatus(e.target.value);
    setStates(
      { ...rest,
        ...INVOICE_OPERATION_STATES_OPTIONS.find(
          (el) => el.value === e.target.value,
        ).filter,
      },
    );
  };
  return (
    <FormControl sx={{ width: '80px' }}>
      <Select
        name="invoiceStatus"
        value={selectedStatus}
        onChange={handleChangeInvoiceStatus}
        variant="standard"
        disableUnderline
      >
        {INVOICE_OPERATION_STATES_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
InvoiceStatusFilter.propTypes = {
  states: PropTypes.shape({
    dteType_CountryId: PropTypes.string,
    hasActiveOperation: PropTypes.bool,
    preoffer_Preofferevaluationrequest_RequestingPlatform_Code: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setStates: PropTypes.func.isRequired,
};

export default InvoiceStatusFilter;
