import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { MailType, formatSelectedMail } from '../../propTypes';
import { useAsyncHookSetter, useGetMails } from '../../hooks';

const MailFilter = ({ selectedMail, setSelectedMail, specificMode, filterMode, ...props }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const { mails } = useGetMails({ globalFilter });
  const [setAsyncHook] = useAsyncHookSetter(setGlobalFilter);

  return (
    <Autocomplete
      id="select-mail"
      disablePortal
      options={mails}
      disabled={specificMode}
      getOptionLabel={(option) => option?.emailName || ''}
      onChange={(_, value) => {
        // eslint-disable-next-line no-unused-expressions
        value ? setSelectedMail(formatSelectedMail(value)) : setSelectedMail(null);
      }}
      value={selectedMail}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(_options, params) => {
        const filtered = _options.filter(
          (option) => option.emailName.toLowerCase().includes(params.inputValue.toLowerCase()),
        );
        setAsyncHook(params.inputValue);
        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={filterMode ? 'Filtrar por mail' : 'Seleccionar mail'}
          InputProps={{ ...params.InputProps }}
        />
      )}
      {...props}
    />
  );
};

MailFilter.propTypes = {
  selectedMail: MailType,
  setSelectedMail: PropTypes.func.isRequired,
  specificMode: PropTypes.bool,
  filterMode: PropTypes.bool,
};

MailFilter.defaultProps = {
  selectedMail: null,
  filterMode: false,
  specificMode: false,
};

export default MailFilter;
