import React from 'react';
import NewReleases from '@mui/icons-material/NewReleases';
import Tooltip from '@mui/material/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import { PropTypes } from 'prop-types';

const PendingRestrictionsFilter = ({ states, setStates }) => {
  const theme = useTheme();
  const { filterByPendingRestrictions, ...rest } = states;

  return (
    <Tooltip title="Filtrar por Restricciones Pendientes">
      <NewReleases
        sx={{
          width: 25,
          height: 25,
          cursor: 'pointer',
          color: filterByPendingRestrictions
            ? theme.palette.error.secondary : theme.palette.action.disabled,
        }}
        onClick={() => {
          setStates({
            ...rest,
            filterByPendingRestrictions: !filterByPendingRestrictions,
          });
        }}
      />
    </Tooltip>
  );
};

PendingRestrictionsFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setStates: PropTypes.func.isRequired,
};

export default PendingRestrictionsFilter;
