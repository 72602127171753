import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import INVOICE_CESSION_STATES_OPTIONS from '../../constants/invoice-cession-states-options';

const CessionInvoicesFilter = ({ setCessionInvoicesCallback }) => {
  const [selectedFilter, setSelectedFilter] = useState(INVOICE_CESSION_STATES_OPTIONS[0].value);
  useEffect(() => {
    setCessionInvoicesCallback(INVOICE_CESSION_STATES_OPTIONS[0].filter);
  }, []);

  const setCessionInvoices = (e) => {
    const selectedOption = INVOICE_CESSION_STATES_OPTIONS
      .find((status) => status.value === e.target.value);
    setSelectedFilter(selectedOption.value);
    setCessionInvoicesCallback(selectedOption.filter);
  };
  const theme = useTheme();

  return (
    <FormControl fullWidth>
      <Select
        name="filterType"
        value={selectedFilter}
        onChange={setCessionInvoices}
        sx={{
          minHeight: '50px',
          fontSize: '14px',
          backgroundColor: 'white',
          borderRadius: '10px',
          color: theme.palette.primary.main,
          borderWidth: 1,
          borderStyle: 'solid',
          textAlign: 'center',
          borderColor: theme.palette.primary.main,
          '& .MuiSelect-icon': {
            color: theme.palette.primary.main,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.primary.main,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main,
            },
          },
        }}
      >
        {INVOICE_CESSION_STATES_OPTIONS.map((option) => (
          <MenuItem value={option.value} key={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CessionInvoicesFilter.propTypes = {
  setCessionInvoicesCallback: PropTypes.func,
};

CessionInvoicesFilter.defaultProps = {
  setCessionInvoicesCallback: () => {},
};

export default CessionInvoicesFilter;
