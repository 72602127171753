import React, { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useIsMobile } from '../../hooks';
import useWindowWidth from '../../hooks/useWindowWidth';

const EmbedYouTubeDialog = ({ videoId, openVideo, toggleOpenVideo }) => {
  const isMobile = useIsMobile();
  const innerWidth = useWindowWidth();
  const aspectRatio = 9 / 16;
  const width = useMemo(() => {
    if (isMobile) {
      return '100%';
    }
    return 800;
  }, [isMobile]);
  const height = isMobile ? innerWidth * aspectRatio : width * aspectRatio;
  return (
    <Dialog
      open={openVideo}
      onClose={toggleOpenVideo}
      maxWidth="xl"
      fullScreen={isMobile}
      zIndex={2000}
      PaperProps={{
        sx: {
          overflowY: 'unset',
          backgroundColor: {
            xs: 'black',
            md: null,
          },
        },
      }}
    >
      <IconButton
        sx={(theme) => ({
          position: 'absolute',
          right: {
            md: '-2%',
            xs: '0',
          },
          top: {
            md: '-4%',
            xs: '0',
          },
          backgroundColor: theme.palette.background.dark,
        })}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        size="small"
        onClick={toggleOpenVideo}
      >
        <CloseIcon />
      </IconButton>
      <YouTube
        videoId={videoId}
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
        opts={{
          width,
          height,
        }}
      />
    </Dialog>
  );
};

EmbedYouTubeDialog.propTypes = {
  videoId: PropTypes.string.isRequired,
  openVideo: PropTypes.bool.isRequired,
  toggleOpenVideo: PropTypes.func.isRequired,
};

export default EmbedYouTubeDialog;
