import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
  Chip,
} from '@mui/material';
import { useAsyncHookSetter, useGetUserMails } from '../../hooks';
import { UserMailType } from '../../propTypes';

const UserMailFilter = ({ id, name, label, user, userList, setFieldValue, mode }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const isStaff = useMemo(() => (mode !== 'multiple' ? true : null), [mode]);
  const { users } = useGetUserMails({ isStaff, globalFilter });
  const getInitialSelectedUsers = () => (mode === 'multiple' ? userList : user);
  const [selectedUsers, setSelectedUsers] = useState(getInitialSelectedUsers());

  useEffect(() => {
    setSelectedUsers(getInitialSelectedUsers());
  }, [user, userList]);

  const [setAsyncHook] = useAsyncHookSetter(setGlobalFilter);

  return (
    <Autocomplete
      id={id}
      disablePortal
      multiple={mode === 'multiple'}
      filterSelectedOptions
      options={users}
      getOptionLabel={(option) => option?.email || ''}
      onChange={(_, value) => { setSelectedUsers(value); setFieldValue(name, value); }}
      sx={{ minWidth: 90 }}
      value={selectedUsers}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(_options, params) => {
        setAsyncHook(params.inputValue);
        return _options;
      }}
      size="small"
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip key={option.id} label={option?.email} {...getTagProps({ index })} sx={{ fontSize: '0.7rem', height: 'auto' }} />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          id={mode === 'multiple' ? 'demo-multiple-chip-label' : 'demo-simple-select-label'}
          labelId="demo-simple-select-label"
          label={label}
          name={name}
          InputProps={{ ...params.InputProps }}
        />
      )}
    />
  );
};

UserMailFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  user: PropTypes.shape(UserMailType),
  userList: PropTypes.arrayOf(PropTypes.shape(UserMailType)),
  setFieldValue: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};

UserMailFilter.defaultProps = {
  user: null,
  userList: [],
};
export default UserMailFilter;
